import React from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import usePageData from "../../state-manipulation/hooks/usePageData";
import useUserData from "../../state-manipulation/hooks/useUserData";
import IsAuthorized from "../common/IsAuthorized";

export default function PrepMenu({ onClick }) {
  let { company } = useParams();
  const [pageData] = usePageData();
  const [userData, userDispatch] = useUserData();

  if (!company) {
    company = userData.orgs[0].name.toLowerCase();
  }

  return (
    <IsAuthorized permission="hasPrep">
      <Menu.Item>
        <Menu.Header>Prep</Menu.Header>
        <Menu.Menu>
          <IsAuthorized permission="hasPrepProductLabelPrinter">
            <Menu.Item
              as={Link}
              to={"/" + company + "/prep/product-label-printer"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "PRODUCT LABEL PRINTER"
                  ? true
                  : false
              }
            >
              <Icon name="gamepad" />
              Product Label Printer
            </Menu.Item>
          </IsAuthorized>
          <IsAuthorized permission="hasPrepAddressLabelPrinter">
            <Menu.Item
              as={Link}
              to={"/" + company + "/prep/address-label-printer"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "ADDRESS LABEL PRINTER"
                  ? true
                  : false
              }
            >
              <Icon name="gamepad" />
              Address Label Printer
            </Menu.Item>
          </IsAuthorized>
        </Menu.Menu>
      </Menu.Item>
    </IsAuthorized>
  );
}

PrepMenu.propTypes = {
  onClick: PropTypes.func
};
