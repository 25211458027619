import AxiosWrapper from '../utils/AxiosWrapper';

export async function GetPrintersInfo({ company }) {
  try {
    let url = `${process.env.API_URL}/${company}/prep-printers`;

    let res = await AxiosWrapper({
      method: 'GET',
      url: url,
      isAuthRoute: true,
    });

    return res;
  } catch (e) {
    console.error(e);
  }
}


export async function PrintProductLabel({ company, options }) {
  let {
    madeOnDate,
    quantity,
    labelId,
    deviceId,
    memo,
    employeeByPin,
  } = options;

  try {
    let url = `${process.env.API_URL}/${company}/prep-printers/${deviceId}/product-labels?action=print`;

    let res = await AxiosWrapper({
      method: 'POST',
      url: url,
      isAuthRoute: true,
      data: {
        madeOnDate: madeOnDate,
        quantity: quantity,
        labelId: labelId,
        memo: memo,
        employeeByPin: employeeByPin,
      },
    });

    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function PrintAddressLabel({ company, options }) {
  let {
    quantity,
    deviceId,
    address,
  } = options;

  try {
    let url = `${process.env.API_URL}/${company}/prep-printers/${deviceId}/address-labels?action=print`;

    let res = await AxiosWrapper({
      method: 'POST',
      url: url,
      isAuthRoute: true,
      data: {
        quantity: quantity,
        address: address
      },
    });

    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function GetProductLabels({company}) {
  try {
    let url = `${process.env.API_URL}/${company}/product-labels`;

    let res = await AxiosWrapper({
      method: 'GET',
      url: url,
      isAuthRoute: true,
    });

    return res;
  } catch (e) {
    console.error(e);
  }
}
