import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import usePageData from '../state-manipulation/hooks/usePageData';
import { setSelectedPage } from '../state-manipulation/reducers/page-data/types';
import {
  Segment,
  Dimmer,
  Loader,
  Form,
  Button,
  Progress,
  Label,
} from 'semantic-ui-react';
import useUserData from '../state-manipulation/hooks/useUserData';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import PageHeader from '../components/common/PageHeader';
import { GetPrintersInfo, GetProductLabels, PrintAddressLabel, PrintProductLabel } from '../api/PrepPrinterAPI';



export default function AddressLabelPrinter({ className }) {
  const { company } = useParams();
  const [pageData, pageDispatch] = usePageData();
  const [userData, userDispatch] = useUserData();
  const [isLoading, setIsLoading] = useState(false);
  const [madeOnDate, setMadeOnDate] = useState(moment().format('MM/DD/YY'));
  const [labelList, setLabelList] = useState([]);
  const [labelId, setLabelId] = useState('');
  const [isPrintDisabled, setIsPrintDisabled] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [address, setAddress] = useState('');
  const [printerInfo, setPrinterInfo] = useState({});
  const [printStatusMessage, setPrintStatusMessage] = useState('');
  const [isPrintError, setIsPrintError] = useState(false);
  const [statusPercentage, setStatusPercentage] = useState(0);


  const getProductLabels = async () => {
    try {
      setIsLoading(true);
      let res = await GetProductLabels({ company: company });

      let labelList = res.data.map(l => {
        return {
          text: l.productName,
          key: l.id,
          value: l.id
        }
      });
      setLabelList(labelList);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getPrintersInfo = async () => {
    try {
      setIsLoading(true);
      let res = await GetPrintersInfo({ company: company });

      if (res.data.length > 0) {
        setPrinterInfo(res.data[0]);
      } else {
        setPrinterInfo({});
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const initiateSSE = () => {
    let url = `https://api.losant.com/applications/${printerInfo.applicationId}/devices/${printerInfo.deviceId}/stateStream?jwt=${printerInfo.token}`;
    let es = new EventSource(url);
    es.addEventListener('deviceState', function (e) {
      let data = JSON.parse(e.data).data;
    });
  };

  const handleMadeOnDateChange = (e, { value }) => {
    setMadeOnDate(value);
  };

  const handleQuantityChange = (e, { value }) => {
    setQuantity(value);
  };

  const handleLabelChange = (e, { value }) => {
    setLabelId(value);
  };

  const handleAddressChange = (e, { value }) => {
    setAddress(value);
  };

  const validateForm = () => {

    if (!printerInfo.deviceId) {
      setIsPrintDisabled(true);
      return;
    }

    if (quantity <= 0) {
      setIsPrintDisabled(true);
      return;
    }

    if (address.trim().length === 0) {
      setIsPrintDisabled(true);
      return;
    }

    setIsPrintDisabled(false);
  };

  const printProductLabel = async () => {
    try {
      let data = {
        address: address,
        quantity: quantity,
        labelId: labelId,
        deviceId: printerInfo.deviceId,
        employeeByPin: userData.employeeByPin,
      };

      setStatusPercentage(50);
      setIsPrintDisabled(true);
      setPrintStatusMessage('Printing...');
      let res = await PrintAddressLabel({ company: company, options: data });

      setIsPrintDisabled(false);
      setIsPrintError(false);
      setStatusPercentage(100);
      resetCheckFields();
      setPrintStatusMessage('Success');
      setTimeout(resetStatusIndicators, 2000);
    } catch (error) {
      setIsPrintDisabled(false);
      setIsPrintError(true);
      setStatusPercentage(100);
      setPrintStatusMessage('Error');
      console.error(error);
    }
  };

  const resetCheckFields = () => {
    setQuantity(1);
    setLabelId('');
    setAddress('');
  };

  const resetStatusIndicators = () => {
    setStatusPercentage(0);
    setIsPrintError(false);
    setPrintStatusMessage('');
  };

  const showConnectionStatus = () => {
    let color;
    let printerStatus = 'checking';

    if (printerInfo.connectionInfo?.connected === 1) {
      color = 'green';
      printerStatus = 'connected';
    } else if (printerInfo.connected === 0) {
      color = 'red';
      printerStatus = 'disconnected';
    }

    return <Label color={color}>{printerStatus}</Label>;
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'ADDRESS LABEL PRINTER',
      },
    });
  }, []);

  useEffect(() => {
    if (company) {
      getProductLabels()
      getPrintersInfo();
    }
  }, [company]);

  useEffect(() => {
    validateForm();
  }, [madeOnDate, quantity, labelId, address]);

  useEffect(() => {
    if (printerInfo.applicationId) {
      initiateSSE();
    }
  }, [printerInfo]);

  return (
    <div className={'content-container ' + className}>
      <PageHeader pageName="Print Product Labels" orgName={userData.selectedOrg?.name} appendContent={showConnectionStatus()} />
      <Segment>
        <Dimmer active={isLoading}>
          <Loader />
        </Dimmer>
        <Progress percent={statusPercentage} autoSuccess error={isPrintError}>
          {printStatusMessage}
        </Progress>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              label="Quantity*"
              placeholder=""
              type="number"
              value={quantity}
              inputMode="numeric"
              pattern="[0-9]*"
              onChange={handleQuantityChange}
            />
          </Form.Group>
          <Form.Dropdown
            placeholder="Select Label"
            fluid
            selection
            value={labelId}
            options={labelList}
            onChange={handleLabelChange}
          />
          <Form.TextArea
              label="Address"
              value={address}
              onChange={handleAddressChange}
            ></Form.TextArea>
          <Button primary disabled={isPrintDisabled} onClick={printProductLabel}>
            Print Label
          </Button>
          <p>* = required</p>
        </Form>
      </Segment>
    </div>
  );
}

AddressLabelPrinter.propTypes = {
  className: PropTypes.string,
};

AddressLabelPrinter.defaultProps = {
  className: '',
};
