import React, { useEffect } from "react";
import { firebase } from "@firebase/app";
import Login from "./layouts/Login";
import ForgotPassword from "./layouts/ForgotPassword";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import UserDataProvider from "./state-manipulation/providers/UserDataProvider";
import ApplicationNavigation from "./components/ApplicationNavigation";

import "./scss/_settings.scss";
import PageDataProvider from "./state-manipulation/providers/PageDataProvider";
import useUserData from "./state-manipulation/hooks/useUserData";
import { _ } from "core-js";
import DateRangeDataProvider from "./state-manipulation/providers/DateRangeDataProvider";
import SalesOverview from "./components/Sales/SalesOverview";
import Alerts from "./pages/Alerts";
import SalesStats from "./components/Sales/SalesStats";
import ProductMix from "./components/Sales/ProductMix";
import Discounts from "./components/Sales/Discounts";
import Voids from "./components/Sales/Voids";
import TaskLists from "./pages/TaskLists";
import TaskList from "./pages/TaskList";
import TaskListTemplates from "./pages/TaskListTemplates";
import TaskListTemplate from "./pages/TaskListTemplate";
import TaskListTemplateMerged from "./pages/TaskListTemplateMerged";
import ManageDraftList from "./components/drafts/ManageDraftList";
import PricingCalculator from "./components/pricing-calculator/PricingCalculator";
import ViewLabor from "./components/labor/ViewLabor";
import CashCount from "./components/cash/CashCount";
import CashTransactions from "./components/cash/CashTransactions";
import StockPots from "./pages/StockPots";
import StockPotsLive from "./components/iot/StockPotsLive";
import StockPotsHistorical from "./components/iot/StockPotsHistorical";
import Refrigerators from "./pages/Refrigerators";
import PrintChecks from "./components/checks/PrintChecks";
import AddPayment from "./components/checks/AddPayment";
import ManagePayments from "./components/checks/ManagePayments";
import ManageVendors from "./components/checks/ManageVendors";
import ManageUsers from "./components/super-admin/ManageUsers";
import UserForm from "./pages/UserForm";
import ChangeLog from "./pages/ChangeLog";
import IsAuthorized from "./components/common/IsAuthorized";
import { TeamSiteRoutes } from "./routes/TeamSiteRoutes";
import LogOut from "./pages/LogOut";
import PeriodReports from "./pages/PeriodReports";
import ProductItemSales from "./pages/ProductItemSales";
import { DateRangeProvider } from "./context/DateRangeContext";
import PayrollExport from "./pages/PayrollExport";
import TrelloTools from "./components/tasks/TrelloTools";
import TrainingAdmin from "./pages/TrainingAdmin";
import TrainingAdminEmployeeDetail from "./pages/TrainingAdminEmployeeDetail";
import { EmployeesTrainingStatusProvider } from "./context/EmployeesTrainingStatusContext";
import QRCodeGenerator from "./pages/QRCodeGenerator";
import ProductLabelPrinter from "./pages/ProductLabelPrinter";
import AddressLabelPrinter from "./pages/AddressLabelPrinter";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_APIKEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGINGSENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};
// Initialize Firebase

firebase.initializeApp(firebaseConfig);

const BASE_NAME = '/app';

function App() {
  const { company } = useParams();

  useEffect(() => {
    const pathParts = window.location.pathname.split('/');
    if (`/${pathParts[1]}` !== BASE_NAME) {
      window.location.href = BASE_NAME;
    }
  }, []);


  return (
    <Router basename={BASE_NAME}>
      <PageDataProvider>
        <UserDataProvider>
          <Routes>
            <Route exact path="/" element={<RedirectToHomeScreen />}></Route>
            <Route exact path="/login" element={<Login />}></Route>
            <Route exact path="/logout" element={<LogOut />}></Route>
            <Route
              exact
              path="/forgot-password"
              element={<ForgotPassword />}
            ></Route>
            <Route
              exact
              path="/forgot-password/:resetKey"
              element={<ForgotPassword />}
            ></Route>

            <Route
              path="/all"
              element={
                <RequireAuth>
                  <ApplicationNavigation />
                </RequireAuth>
              }
            >
              <Route exact path="change-log" element={<ChangeLog />} />
              <Route
                path="admin/manage-users"
                element={
                  <IsAuthorized permission="hasManageUsers" redirect>
                    <ManageUsers />
                  </IsAuthorized>
                }
              />
              <Route
                path="admin/manage-users/new"
                element={
                  <IsAuthorized permission="hasManageUsers" redirect>
                    <UserForm />
                  </IsAuthorized>
                }
              />
            </Route>
            <Route
              exact
              path="/:company"
              element={
                <RedirectToHomeScreen />
              }
            />
            <Route
              path="/:company"
              element={
                <RequireAuth>
                  <ApplicationNavigation />
                </RequireAuth>
              }
            >
              <Route path="home" element={<RedirectToHomeScreen />} />
              {TeamSiteRoutes()}
              <Route
                exact
                path="sales"
                element={
                  <IsAuthorized permission="hasSalesOverview" redirect>
                    <DateRangeDataProvider>
                      <SalesOverview />
                    </DateRangeDataProvider>
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="sales/product-mix"
                element={
                  <IsAuthorized permission="hasSalesProductMix" redirect>
                    <DateRangeDataProvider>
                      <ProductMix />
                    </DateRangeDataProvider>
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="sales/discounts"
                element={
                  <IsAuthorized permission="hasSalesDiscounts" redirect>
                    <DateRangeDataProvider>
                      <Discounts />
                    </DateRangeDataProvider>
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="sales/voids"
                element={
                  <IsAuthorized permission="hasSalesVoids" redirect>
                    <DateRangeDataProvider>
                      <Voids />
                    </DateRangeDataProvider>
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="sales-stats"
                element={
                  <IsAuthorized permission="hasSalesStats" redirect>
                    <DateRangeDataProvider>
                      <SalesStats />
                    </DateRangeDataProvider>
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="sales/product-item-sales"
                element={
                  <IsAuthorized permission="hasSalesProductItemSales" redirect>
                    <DateRangeProvider>
                      <ProductItemSales />
                    </DateRangeProvider>
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="sales/period-reports"
                element={
                  <IsAuthorized permission="hasSalesPeriodReports" redirect>
                    <PeriodReports />
                  </IsAuthorized>
                }
              />
              <Route
                path="alerts"
                element={
                  <IsAuthorized permission="hasAlerts" redirect>
                    <DateRangeDataProvider>
                      <Alerts />
                    </DateRangeDataProvider>
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="prep/product-label-printer"
                element={
                  <IsAuthorized permission="hasPrepProductLabelPrinter" redirect>
                    <ProductLabelPrinter />
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="prep/address-label-printer"
                element={
                  <IsAuthorized permission="hasPrepAddressLabelPrinter" redirect>
                    <AddressLabelPrinter />
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="task-lists"
                element={
                  <IsAuthorized permission="hasTasksTaskLists" redirect>
                    <DateRangeDataProvider>
                      <TaskLists />
                    </DateRangeDataProvider>
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="task-lists/:taskListId"
                element={
                  <IsAuthorized permission="hasTasksTaskLists" redirect>
                    <DateRangeDataProvider>
                      <TaskList />
                    </DateRangeDataProvider>
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="trello-tools"
                element={
                  <IsAuthorized permission="hasTasksTrelloTools" redirect>
                    <DateRangeProvider>
                      <TrelloTools />
                    </DateRangeProvider>
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="admin/task-list-templates"
                element={
                  <IsAuthorized permission="hasTasksManage" redirect>
                    <TaskListTemplates />
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="admin/task-list-templates/new"
                element={
                  <IsAuthorized permission="hasTasksManage" redirect>
                    <TaskListTemplate />
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="admin/task-list-templates/merged"
                element={
                  <IsAuthorized permission="hasTasksManage" redirect>
                    <TaskListTemplateMerged />
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="admin/task-list-templates/:taskListTemplateId"
                element={
                  <IsAuthorized permission="hasTasksManage" redirect>
                    <TaskListTemplate />
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="bar-tools/draft-list"
                element={
                  <IsAuthorized permission="hasBarToolsDraftList" redirect>
                    <ManageDraftList />
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="bar-tools/pricing-calculator"
                element={
                  <IsAuthorized permission="hasBarToolsPriceCalculator" redirect>
                    <PricingCalculator />
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="labor"
                element={
                  <IsAuthorized permission="hasEmployeesLabor" redirect>
                    <DateRangeDataProvider>
                      <ViewLabor />
                    </DateRangeDataProvider>
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="training/admin"
                element={
                  <IsAuthorized permission="hasTrainingAdmin" redirect>
                    <EmployeesTrainingStatusProvider>
                      <TrainingAdmin selectedPage="TRAINING ADMIN" />
                    </EmployeesTrainingStatusProvider>
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="training/me"
                element={
                  <IsAuthorized permission="hasTraining" redirect>
                    <EmployeesTrainingStatusProvider>
                      <TrainingAdminEmployeeDetail selectedPage="MY TRAINING" />
                    </EmployeesTrainingStatusProvider>
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="training/admin/employees/:externalEmployeeId"
                element={
                  <IsAuthorized permission="hasTrainingAdmin" redirect>
                    <EmployeesTrainingStatusProvider>
                      <TrainingAdminEmployeeDetail selectedPage="TRAINING ADMIN" />
                    </EmployeesTrainingStatusProvider>
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="cash-count"
                element={
                  <IsAuthorized permission="hasCashPaymentsCashCount" redirect>
                    <CashCount />
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="cash-transactions"
                element={
                  <IsAuthorized permission="hasCashPaymentsPOSCashTransactions" redirect>
                    <DateRangeDataProvider>
                      <CashTransactions />
                    </DateRangeDataProvider>
                  </IsAuthorized>
                }
              />
              <Route
                path="stock-pots"
                element={
                  <IsAuthorized permission="hasIoTStockPots" redirect>
                    <StockPots />
                  </IsAuthorized>
                }
              >
                <Route path="live" element={<StockPotsLive />} />
                <Route path="historical" element={<StockPotsHistorical />} />
              </Route>
              <Route
                exact
                path="refrigerators"
                element={
                  <IsAuthorized permission="hasIoTRefrigerators" redirect>
                    <Refrigerators />
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="print-checks"
                element={
                  <IsAuthorized permission="hasCashPaymentsPrintChecks" redirect>
                    <PrintChecks />
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="add-payments"
                element={
                  <IsAuthorized permission="hasCashPaymentsAddPayments" redirect>
                    <AddPayment noPrint={true} />
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="admin/payments"
                element={
                  <IsAuthorized permission="hasCashPaymentsViewPayments" redirect>
                    <ManagePayments />
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="admin/vendors"
                element={
                  <IsAuthorized permission="hasCashPaymentsViewPayments" redirect>
                    <ManageVendors />
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="admin/payroll-export"
                element={
                  <IsAuthorized permission="hasAdminPayroll" redirect>
                    <PayrollExport selectedPage="PAYROLL EXPORT" pageName="Payroll Export" />
                  </IsAuthorized>
                }
              />
              <Route
                exact
                path="admin/qr-code-generator"
                element={
                  <IsAuthorized permission="hasAdminQRCodeGenerator" redirect>
                    <QRCodeGenerator selectedPage="QR Code Generator" pageName="QR Code Generator" />
                  </IsAuthorized>
                }
              />
            </Route>
          </Routes>
        </UserDataProvider>
      </PageDataProvider>
    </Router>
  );
}

function RequireAuth({ children }) {
  let location = useLocation();
  const [userData] = useUserData();

  if (!userData.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function RedirectToHomeScreen() {
  let { company } = useParams();
  const [userData] = useUserData();

  if (!company && userData?.orgs) {
    company = userData?.orgs[0].name.toLowerCase();
  }

  if (!company || !userData?.userInfo?.userTags?.homeScreen) {
    return <Navigate push to="/login" />
  }

  return (
    <Navigate
      push
      to={`/${company}/${userData.userInfo.userTags.homeScreen}`}
    />
  );
}

export default App;
